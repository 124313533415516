<script lang="ts" setup>
const props = defineProps<{
  inverseUncheckedBg?: boolean
  checked?: boolean | undefined | null
}>()

const icon = computedAsync(async () => {
  if (!process.client) {
    return null
  }

  if (props.checked) {
    return (await import('@cfgtech/icons')).CheckmarkIcon
  }

  if (props.checked === false) {
    return (await import('@cfgtech/icons')).TickIcon
  }

  return null
}, null)
</script>

<template>
  <span
    class="flex size-[1em] min-h-[1em] items-center justify-center rounded-full"
    :class="{
      'bg-success': checked,
      'bg-white shadow-base': !checked && inverseUncheckedBg,
      'bg-grey-100': !checked && !inverseUncheckedBg,
    }"
  >
    <ClientOnly v-if="icon">
      <Component
        :is="icon"
        class="block text-[0.55em]"
        :class="{
          'text-white': checked,
          'text-grey-300': !checked && !inverseUncheckedBg,
        }"
      />
    </ClientOnly>

    <span v-if="checked === undefined || checked === null" class="block align-middle text-[10px] leading-none text-grey-300">?</span>
  </span>
</template>
